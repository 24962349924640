<template>
  <div :class="[b(),className]"
       :style="styleSizeName">
    <div :ref="id"
         :style="styleChartName"></div>
  </div>
</template>

<script>
import { getFunction } from '@/utils/utils';
import create from "../../create";
export default create({
  name: "common",
  computed: {
    src () {
      return this.option.src;
    },
    remote () {
      return this.option.remote;
    }
  },
  watch: {
    src () {
      this.updateChart()
    },
    remote () {
      this.updateChart()
    }
  },
  methods: {
    getTemplate () {
      return new Promise((resolve, reject) => {
        let result;
        if (this.remote && this.src) {
          this.$axios.get(this.src).then(res => {
            result = getFunction(res.data)
            resolve(result)
          }).catch(() => {
            resolve(() => { })
          })
        } else {
          resolve(this.echartFormatter)
        }
      })
    },
    updateChart () {
      const optionData = this.deepClone(this.dataChart) || [];
      this.getTemplate().then(res => {
        const option = res && res(optionData, this.dataParams);
        this.myChart.resize();
        this.myChart.setOption(option, this.initialize);
        this.bindEvent()
      })

    }
  }
});
</script>



