<template>
  <div :class="[b(),className]"
       @mouseenter="handleMouseEnter"
       @mouseleave="handleMouseLeave"
       @dblclick="handleDblClick"
       :style="[styleSizeName]">
    <component ref="main"
               :is="id"
               :style="styleChartName"
               v-if="reload"
               @click="handleClick"
               :refList="refList"
               :dataChart="dataChart">
    </component>
  </div>
</template>

<script>
import Vue from 'vue'
import create from "../../create";
export default create({
  name: "vue",
  data () {
    return {
      template: '',
      reload: false
    };
  },
  watch: {
    src () {
      this.initVue()
    },
    remote () {
      this.initVue()
    },
    content () {
      this.initVue()
    }
  },
  computed: {
    src () {
      return this.option.src;
    },
    content () {
      return this.option.content;
    },
    remote () {
      return this.option.remote;
    }
  },
  mounted () {
    this.initVue()
  },
  methods: {
    getTemplate () {
      return new Promise((resolve, reject) => {
        if (this.remote && this.src) {
          this.$axios.get(this.src).then(res => {
            resolve(res.data)
          }).catch(() => {
            resolve()
          })
        } else {
          resolve(this.content)
        }
      })
    },
    initVue () {
      this.reload = false
      this.getTemplate().then(res => {
        this.template = res;
        let template = this.getSource("template");
        if (!template) return
        let script = this.getSource("script");
        if (script) {
          script = script.replace(/export default/, "return");
        }
        let styleCss = this.getSource("style");
        let styleId = 'style-' + this.id;
        if (document.getElementById(styleId)) {
          document.getElementById(styleId).remove()
        }
        let style = document.createElement("style");
        style.id = styleId
        style.innerHTML = styleCss;
        document.head.appendChild(style);
        let obj = new Function(script)();
        obj.template = template;
        obj.props = {
          dataChart: Object,
          refList: Array
        }
        Vue.component(this.id, obj)
        this.$nextTick(() => {
          this.reload = true
        })
      })
    },
    getSource (type) {
      const reg = new RegExp(`<${type}[^>]*>`);
      let content = this.template;
      let matches = content.match(reg);
      if (matches) {
        let start = content.indexOf(matches[0]) + matches[0].length;
        let end = content.lastIndexOf(`</${type}`);
        return content.slice(start, end)
      }
    }
  }
});
</script>


